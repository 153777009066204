// import svg1 from '../../assets/home/u65653.svg';
// import svg2 from '../../assets/home/u65654.svg';
// import svg3 from '../../assets/home/u65652.svg';
// import svg4 from '../../assets/home/u65655.svg';
import svg1 from '/home-svg/u65653.svg';
import svg2 from '/home-svg/u65654.svg';
import svg3 from '/home-svg/u65652.svg';
import svg4 from '/home-svg/u65655.svg';


const logo = '/home/logo.png';
const firstBg = '/home/first-bg.jpg';
const first1 = '/home/first-1.png';
const img0 = '/home/u65602.png';
const img1 = '/home/u65615.png';
const img2 = '/home/u65616.png';
const img3 = '/home/u65617.png';
const img4 = '/home/u65600.png';
const img5 = '/home/u65605.png';
const img6 = '/home/u65570.png';
const img7 = '/home/u65604.png';
const img8 = '/home/u65396.webp';
const QRcode = '/home/QRcode.png';
const svg0 = `<svg t="1687326494928" class="icon" viewBox="0 0 1024 1024" version="1.1"
xmlns="http://www.w3.org/2000/svg" p-id="1530" width="50" height="50">
<path d="M0 0h1024v1024H0z" fill="#D8D8D8" fill-opacity="0" p-id="1531"></path>
<path d="M814.994885 513.796197v284.21036a36.847213 36.847213 0 0 1-36.843016 36.843017H267.624918a36.847213 36.847213 0 0 1-36.83882-36.843017V513.796197a36.847213 36.847213 0 0 1 36.843017-36.843017h510.531147a36.847213 36.847213 0 0 1 36.83882 36.843017" fill="#B9C0C8" fill-opacity=".18" p-id="1532"></path>
<path d="M858.624 413.251148v326.664393c0 23.375738-18.964984 42.344918-42.344918 42.344918H229.493508c-23.375738 0-42.344918-18.96918-42.344918-42.344918V413.251148c0-23.379934 18.96918-42.344918 42.344918-42.344918h586.785574c23.379934 0 42.344918 18.964984 42.344918 42.344918"  fill="#E8EEF6" p-id="1533" data-spm-anchor-id="a313x.7781069.0.i7" class="selected"></path>
<path d="M187.152787 482.018623l112.707147 112.711344-112.707147 112.707148zM558.82282 370.90623l-208.342033 208.337836-163.332197-163.332197v-2.660721c0-23.379934 18.96918-42.344918 42.344918-42.344918h329.329312zM471.468066 776.208787c0 2.027016-0.058754 4.054033-0.209836 6.051672H284.151607c-0.151082-1.997639-0.209836-4.024656-0.209837-6.051672 0-51.779148 41.979803-93.763148 93.763148-93.763148 51.783344 0 93.763148 41.984 93.763148 93.763148" fill="#D6DFE9" p-id="1534"></path>
<path d="M858.624 609.854951v130.056393c0 23.384131-18.964984 42.344918-42.344918 42.344918H683.22623c1.603148-95.483803 79.519475-172.401311 175.39777-172.401311" fill="#FFFFFF" p-id="1535"></path>
<path d="M604.554492 615.902426c0 6.68118-20.312131 12.099148-45.366558 12.099148-25.058623 0-45.374951-5.41377-45.37495-12.099148 0-6.68118 20.312131-12.099148 45.370754-12.099147 25.054426 0 45.366557 5.417967 45.366557 12.099147" fill="#D7DDE4" p-id="1536"></path>
<path d="M858.624 652.199869v87.711475c0 23.384131-18.964984 42.344918-42.344918 42.344918H725.571148c1.603148-72.103869 60.554492-130.056393 133.052852-130.056393" fill="#D6DFE9" p-id="1537"></path>
<path d="M682.819148 504.361967l-115.342689 108.602754a12.099148 12.099148 0 0 1-16.585443 0L435.552525 504.361967c-74.059541-69.741115-75.822164-186.89259-3.890361-258.824393 70.429377-70.429377 184.61377-70.429377 255.043147 0 71.931803 71.931803 70.16918 189.083279-3.886163 258.824393" fill="#1A66FF" p-id="1538" data-spm-anchor-id="a313x.7781069.0.i5" class="selected"></path>
<path d="M646.89941 370.90623c0 48.446951-39.268721 87.711475-87.711476 87.711475-48.451148 0-87.719869-39.264525-87.719868-87.711475 0-48.446951 39.268721-87.715672 87.715672-87.715673s87.715672 39.268721 87.715672 87.711476" fill="#FFFFFF" p-id="1539" data-spm-anchor-id="a313x.7781069.0.i6" class="selected"></path>
<path
  d="M323.260852 461.643541a12.099148 12.099148 0 1 1-24.198295 0 12.099148 12.099148 0 0 1 24.198295 0"
  fill="#34C8CD" p-id="1540"></path>
<path
  d="M317.213377 461.643541a6.051672 6.051672 0 1 1-12.103344 0 6.051672 6.051672 0 0 1 12.103344 0"
  fill="#D6DFE9" p-id="1541"></path>
<path
  d="M265.790951 727.816393H60.113836A18.15082 18.15082 0 0 1 41.967213 709.665574v-108.888131a18.15082 18.15082 0 0 1 18.146623-18.146623h205.681312a18.15082 18.15082 0 0 1 18.146622 18.146623V709.665574a18.15082 18.15082 0 0 1-18.150819 18.150819"
  fill="#34C8CD" p-id="1542"></path>
<path
  d="M248.194098 751.380984H62.699016a16.367213 16.367213 0 0 1-16.367213-16.367214v-98.203278a16.367213 16.367213 0 0 1 16.367213-16.367213h185.495082a16.367213 16.367213 0 0 1 16.367213 16.367213v98.203278a16.367213 16.367213 0 0 1-16.367213 16.367214"
  fill="#34C8CD" fill-opacity=".2" p-id="1543"></path>
<path
  d="M156.90282 567.510033a6.051672 6.051672 0 1 1-12.103345-0.004197 6.051672 6.051672 0 0 1 12.103345 0"
  fill="#34C8CD" p-id="1544"></path>
<path
  d="M244.618492 624.975738H81.290492a3.021639 3.021639 0 1 1 0-6.047476h163.336393a3.021639 3.021639 0 1 1 0 6.047476M244.618492 658.247344H81.290492a3.021639 3.021639 0 1 1 0-6.047475h163.336393a3.021639 3.021639 0 1 1 0 6.047475M169.001967 691.518951H81.290492a3.021639 3.021639 0 1 1 0-6.047476h87.711475a3.021639 3.021639 0 1 1 0 6.047476"
  fill="#FFFFFF" p-id="1545"></path>
<path
  d="M930.580984 389.052852h-119.711476c-3.693115 0-6.685377 2.996459-6.685377 6.689574v65.263213c0 3.693115 2.992262 6.689574 6.685377 6.689574h119.711476c3.693115 0 6.685377-2.996459 6.685377-6.689574V395.742426c0-3.693115-2.992262-6.689574-6.685377-6.689574"
  fill="#C6ECEF" p-id="1546"></path>
<path
  d="M804.179934 813.731672c0 39.256131-31.819541 71.079869-71.075672 71.079869-39.260328 0-71.079869-31.823738-71.079869-71.079869 0-39.256131 31.819541-71.079869 71.079869-71.079869 39.256131 0 71.075672 31.823738 71.075672 71.079869"
  fill="#80B1FF" fill-opacity=".3" p-id="1547"></path>
<path
  d="M804.179934 783.771279c0 39.256131-31.819541 71.079869-71.075672 71.079869-39.260328 0-71.079869-31.823738-71.079869-71.079869 0-39.256131 31.819541-71.079869 71.079869-71.079869 39.256131 0 71.075672 31.823738 71.075672 71.079869"
  fill="#80B1FF" p-id="1548"></path>
<path
  d="M687.154361 781.072787l33.640918 14.671738 44.246032-44.241836-77.895344 26.351213a1.762623 1.762623 0 0 0 0.008394 3.218885"
  fill="#FFFFFF" p-id="1549"></path>
<path
  d="M765.041311 751.502689l-44.246032 44.241836 15.813246 34.85377c0.629508 1.397508 2.618754 1.372328 3.214688-0.033574l25.218098-79.066229z"
  fill="#D6DFE9" p-id="1550"></path>
<path
  d="M563.720393 821.579541H197.736918a9.073311 9.073311 0 1 1 0-18.146623h365.983475a9.073311 9.073311 0 1 1 0 18.146623M442.733115 863.924459H197.736918a9.073311 9.073311 0 1 1 0-18.146623h244.996197a9.073311 9.073311 0 1 1 0 18.146623"
  fill="#E8EEF6" p-id="1551"></path>
<path
  d="M847.448131 110.868984l32.826754 54.460852a8.267541 8.267541 0 0 0-2.115147 8.343082l-58.838033 43.53259 28.126426-106.336524z m46.83541 61.763147a8.208787 8.208787 0 0 0-0.251803-3.982688 8.305311 8.305311 0 0 0-7.998951-5.707541l-21.76-36.091804 53.264787 60.789509-23.25823-15.007476z m-12.225049 5.820853a8.317902 8.317902 0 0 0 6.626623 0.671475c1.418492-0.461639 2.601967-1.288393 3.537836-2.312393l31.320131 20.186229c-0.01259 0.125902-0.054557 0.239213-0.062951 0.360918l-97.25482 22.402098 55.833181-41.308327z m57.646164 17.038688a8.334689 8.334689 0 0 0-10.500197-5.338229c-0.893902 0.289574-1.678689 0.74282-2.392131 1.275803L852.328918 106.416262c2.08577-2.140328 2.971279-5.308852 1.980852-8.343082a8.330492 8.330492 0 0 0-15.846819 5.140984c0.621115 1.909508 1.892721 3.411934 3.466492 4.406557l-28.697181 108.497836a8.309508 8.309508 0 1 0 3.441312 16.169968 8.309508 8.309508 0 0 0 5.707541-7.050492l101.976131-23.480656a8.317902 8.317902 0 0 0 15.34741-6.269902z"
  fill="#1A66FF" p-id="1552"></path>
<path
  d="M842.260984 909.009836l-16.761705 16.765902a4.192525 4.192525 0 0 1-5.934164 0l-16.761705-16.761705a4.192525 4.192525 0 0 1 0-5.934164l16.761705-16.761705a4.192525 4.192525 0 0 1 5.934164 0l16.761705 16.761705a4.192525 4.192525 0 0 1 0 5.934164"
  fill="#BBD9FA" p-id="1553"></path>
<path
  d="M130.874754 487.843672l-32.478426 32.482623a4.192525 4.192525 0 0 1-5.934164 0l-32.478426-32.482623a4.192525 4.192525 0 0 1 0-5.934164l32.482623-32.474229a4.192525 4.192525 0 0 1 5.929967 0l32.482623 32.474229a4.192525 4.192525 0 0 1 0 5.934164"
  fill="#FFBBD8" p-id="1554"></path>
<path
  d="M898.517185 754.987196m5.93506-5.93506l29.675301-29.675301q5.93506-5.93506 11.87012 0l29.675301 29.675301q5.93506 5.93506 0 11.870121l-29.675301 29.675301q-5.93506 5.93506-11.87012 0l-29.675301-29.675301q-5.93506-5.93506 0-11.870121Z"
  fill="#C4D8FC" p-id="1555"></path>
<path
  d="M76.384567 309.713836m5.93506-5.93506l71.220723-71.220722q5.93506-5.93506 11.87012 0l71.220722 71.220722q5.93506 5.93506 0 11.87012l-71.220722 71.220723q-5.93506 5.93506-11.87012 0l-71.220723-71.220723q-5.93506-5.93506 0-11.87012Z"
  fill="#33C8CD" opacity=".252" p-id="1556"></path>
</svg>`;
export default {
  introduce: {
    title: 'DTBIM数字主线引擎，建筑革新的数字动力',
    describeTop: '打造“基于模型+数字驱动”的公共底座',
    describeBom: '为国产化AEC软件生态建设提供根技术服务',
    bg: `url(${firstBg}) no-repeat`,
    login: '/pm/admin/project',
  },
  fcArr: [
    {
      nameT: '多源数据融合',
      name: '打破信息孤岛',
      // p1: '图模联动',
      p2: '轻松接入各类遥感影像、CAD图纸、BIM模型、倾斜摄影、激光点云、人工模型等数据，实现数据的无缝融合，为工程建设提供更直观、更丰富的的数据参考',
      features: [
        { name: '构件属性', icon: svg0 },
        { name: '空间属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
      ],
      bg: '#F4F6FB',
      eaxmple: `<div style="position: relative;height:300px;width:600px;">
      <img src="${img0}" style="width: 450px; height: 20px; position: absolute; top: -20px; left: 0px" />
      <img src="${img1}" style="width: 450px; height: 225px" />
      <img src="${img2}" style="width: 393px; height: 196px; position: absolute; top: 65px; left: 170px" />
      <img src="${img3}" style="width: 134px; height: 200px; position: absolute; top: 98px; left: 474px" />
      </div>`,
      place: 'left'
    },
    {
      nameT: '海量数据承载',
      name: '项目一屏尽览',
      // p1: '多端畅游',
      p2: '采用先进的数据调度算法，无需担心数据规模带来的限制，无论是复杂的建筑结构、丰富的园区设施，还是庞大的城市规划，都能流畅加载和高效处理',
      features: [
        { name: 'PC端', icon: svg0 },
        { name: '移动端', icon: svg0 },
        { name: '移动端', icon: svg0 },
        { name: '移动端', icon: svg0 },
      ],
      bg: '#EFF2F8',
      eaxmple: `<div style="position: relative;height:400px;width:700px;">
      <img src="${img4}" style="width: 648px; height: 401px; position: absolute; top: -20px;left: -0px;" />
      <img src="${img0}" style="width: 455px; height: 20px; position: absolute; top: 29px; left: 97px" />
      <img src="${img6}" style="width: 455px; height: 265px; position: absolute; top: 49px; left: 97px" />
      <img src="${img7}" style="width: 354px; height: 163px; position: absolute; top: 204px; left: 341px" />
      <img src="${img5}" style="width: 377px; height: 188px; position: absolute; top: 193px; left: 330px" />
      </div>`,
      place: 'right'
    },
    {
      nameT: '全栈自研技术',
      name: '安全自主可控',
      p2: '秉持技术创新的理念，深度融入国产信创生态。全方位适配国产服务器、芯片、操作系统、数据库，实现从底层硬件到上层应用的无缝对接，为数据安全提供无忧保障',
      features: [
        { name: '构件属性', icon: svg0 },
        { name: '空间属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
      ],
      bg: '#F4F6FB',
      eaxmple: `<div style="position: relative;height:300px;width:600px;">
      <img src="${img0}" style="width: 450px; height: 20px; position: absolute; top: -20px; left: 0px" />
      <img src="${img1}" style="width: 450px; height: 225px" />
      <img src="${img2}" style="width: 393px; height: 196px; position: absolute; top: 65px; left: 170px" />
      <img src="${img3}" style="width: 134px; height: 200px; position: absolute; top: 98px; left: 474px" />
      </div>`,
      place: 'left'
    },
    {
      nameT: '多端交互协作',
      name: '实时信息共享',
      p2: '基于统一工作平台，整合各专业模型数据，提供丰富的交互式协作工具，如在线讨论、动态标注、任务分配等，使得团队成员可以围绕BIM模型进行高效的沟通和协作',
      features: [
        { name: 'PC端', icon: svg0 },
        { name: '移动端', icon: svg0 },
        { name: '移动端', icon: svg0 },
        { name: '移动端', icon: svg0 },
      ],
      bg: '#EFF2F8',
      eaxmple: `<div style="position: relative;height:400px;width:700px;">
      <img src="${img4}" style="width: 648px; height: 401px; position: absolute; top: -20px;left: -0px;" />
      <img src="${img0}" style="width: 455px; height: 20px; position: absolute; top: 29px; left: 97px" />
      <img src="${img6}" style="width: 455px; height: 265px; position: absolute; top: 49px; left: 97px" />
      <img src="${img7}" style="width: 354px; height: 163px; position: absolute; top: 204px; left: 341px" />
      <img src="${img5}" style="width: 377px; height: 188px; position: absolute; top: 193px; left: 330px" />
      </div>`,
      place: 'right'
    },
    {
      nameT: '智能数据洞察',
      name: '优化决策过程',
      p2: '内置标准条文库与专家知识库，不仅能够精准地分析模型构件的属性、拓扑等信息，还能识别潜在的设计缺陷、成本超支风险和质量控制问题，为项目调整提供数据依据',
      features: [
        { name: '构件属性', icon: svg0 },
        { name: '空间属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
        { name: '业务属性', icon: svg0 },
      ],
      bg: '#F4F6FB',
      eaxmple: `<div style="position: relative;height:300px;width:600px;">
      <img src="${img0}" style="width: 450px; height: 20px; position: absolute; top: -20px; left: 0px" />
      <img src="${img1}" style="width: 450px; height: 225px" />
      <img src="${img2}" style="width: 393px; height: 196px; position: absolute; top: 65px; left: 170px" />
      <img src="${img3}" style="width: 134px; height: 200px; position: absolute; top: 98px; left: 474px" />
      </div>`,
      place: 'left'
    },
    // {
    //   nameT: '多源数据融合',
    //   name: '轻松访问和同步项目情况',
    //   // p1: '多端畅游',
    //   p2: '自主知识产权，强大的研发团队，核心技术发明专利。无需插件，多平台随开随看。',
    //   features: [
    //     { name: 'PC端', icon: svg0 },
    //     { name: '移动端', icon: svg0 },
    //     { name: '移动端', icon: svg0 },
    //     { name: '移动端', icon: svg0 },
    //   ],
    //   bg: 'rgba(230, 238, 254, 1)',
    //   eaxmple: `
    // <img src="${img4}" style="width: 648px; height: 401px; position: absolute; top: -20px" />
    //   <img src="${img0}" style="width: 455px; height: 20px; position: absolute; top: 29px; left: 97px" />
    //   <img src="${img6}" style="width: 455px; height: 265px; position: absolute; top: 49px; left: 97px" />
    //   <img src="${img7}" style="width: 354px; height: 163px; position: absolute; top: 204px; left: 341px" />
    //   <img src="${img5}" style="width: 377px; height: 188px; position: absolute; top: 193px; left: 330px" />`,
    //   place: 'right'
    // }
  ],
  characteristic: {
    title: '产品特性',
    features: [
      {
        name: '组织管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '项目管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '图模管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '投资管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '进度管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '质量管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '用户管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
      {
        name: '验收管理',
        description: '提供从需求分析、设计、开发、测试到维护运营的全方位软件开发服务，一站式解决所有软件开发需求',
        icon: 'root-list',
      },
    ],
    bg: 'rgba(255,255, 255, 1)',
  },
  sp: {
    title: '更多服务',
    p1: '提供除标准产品外的，专属定制化解决方案',
    features: [
      {
        name: '一体化解决方案',
        icon: svg1,
        description: '为客户提供了集成服务，同时将企业所面临的挑战转化为机遇，创造了更多的商业价值。',
      },
      {
        name: '专业定制化服务',
        icon: svg2,
        description: '我们的专业服务团队可以为客户和合作伙伴提供定制、部署和集成软件产品的各个阶段的支持。',
      },
      {
        name: '私有（本地）部署',
        icon: svg3,
        description: '除模型文件原始属性外，支持挂接业务属性，包括成本、进度、质量、安全等。',
      },
      {
        name: '二次开发-SDK',
        icon: svg4,
        description: '您可以轻松地将产品模块集成到现有系统或工作流中。或者您选择制作全新的应用程序！',
      },
    ],
    bg: 'rgba(230, 238, 254, 1)',
  },
  footer: {
    copywrite: `Copyright © ${new Date().getFullYear()} 国家数字建造技术创新中心`,
    navigation: [
      {
        name: '文档中心',
        enName: 'Document Center',
        item: [
          { name: '客户端SDK文档', href: '/portal/home/docs/client' },
          { name: '服务端API文档', href: '/portal/home/docs/server' },
          { name: '更新日志', href: '#' },
        ],
      },
      {
        name: '联系我们',
        enName: 'Contact Us',
        item: [
          { name: '电子邮箱：weiya_chen@hust.edu.cn', href: '#' },
          { name: '微信公众号：NCTI-DC2022', href: '#' },
          { name: '地址：湖北省武汉市洪山区珞喻路1037号', href: '#' },
        ],
      },
      {
        name: '互动讨论',
        enName: 'Interactive Discussions',
        item: [
          { name: 'DTBIM交流QQ群1：69535164', href: '#' },
          { name: 'DTBIM交流QQ群2：114370227', href: '#' },
        ],
      },
    ],
    copyright: {
      name: '鄂ICP备2022008675号-2',
      href: 'https://beian.miit.gov.cn/'
      // logo,
    },
    bg: `no-repeat url(${img8}) center/100% 100%`,
    qrCode: QRcode,
  },
}

