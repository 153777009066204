import HomePage from './home-page'

export declare namespace Home {
  export { HomePage }
}

export namespace Home {
  Home.homePage = HomePage
}


