import { Base } from '../../../../framework/class-components'
import { Component, Setup } from 'vue-facing-decorator'
import { useState } from '#imports'

import Introduce from '../../components/Introduce.vue'
import FunctionalCharacteristics1 from '../../components/FunctionalCharacteristics1.vue'
// import Characteristic from '../../components/Characteristic.vue'
// import ServicePlus from '../../components/ServicePlus.vue'
import Footer from '../../components/Footer.vue'

import myfrontConfig from './myfront.config';

@Component
export default class HomePage extends Base {
  @Setup(() => {
    const { introduce, characteristic, fcArr, sp, footer } = myfrontConfig;
    return useState(`state:portal:home`, () => {
      return {
        introduce,
        fcArr,
        characteristic,
        sp,
        footer
      }
    })

  })
  //@ts-ignore
  state?: any

  render() {
    const { introduce, fcArr, characteristic, sp, footer } = this.state
    return <>
      <div id="home" class="w-full h-full overflow-y-auto">
        <div class="w-full h-auto relative">
          <Introduce title={introduce.title} describeTop={introduce.describeTop} describeBom={introduce.describeBom} bg={introduce.bg} login={introduce.login}>
          </Introduce>
          {fcArr.map((x: any) => <FunctionalCharacteristics1 name={x.name} nameT={x.nameT} p1={x.p1} p2={x.p2} bg={x.bg} features={x.features} eaxmple={x.eaxmple} place={x.place}>
          </FunctionalCharacteristics1>)
          }
          {/* <Characteristic title={characteristic.title} features={characteristic.features} bg={characteristic.bg}>
          </Characteristic>
          <ServicePlus title={sp.title} p1={sp.p1} bg={sp.bg} features={sp.features}>
          </ServicePlus> */}
          <Footer copywrite={footer.copywrite} navigation={footer.navigation} bg={footer.bg} copyright={footer.copyright} qr-code={footer.qrCode}>
          </Footer>
        </div>
      </div>
    </>
  }

}