<script setup lang="ts">
interface Props {
  title: string;
  describeTop: string;
  describeBom: string;
  bg: string;
  login: string;
}
const props = defineProps<Props>();
const goto = (path: string) => {
  // eslint-disable-next-line no-restricted-globals
  location.href = path;
}
</script>
<template>
  <div class="introduce">

    <div class="introduce-center">
      <p class="introduce-title">{{ title }}</p>
      <p class="introduce-describe-top introduce-describe"> {{ describeTop }}</p>
      <p class="introduce-describe"> {{ describeBom }}</p>
      <button @click="() => { goto(login) }">立即前往体验 <t-icon name="arrow-right"></t-icon></button>
    </div>
  </div>
</template>

<style scoped>
.introduce {
  width: 100%;
  min-height: 900px;
  background: v-bind(bg);
  background-size: 100% 100%;
  align-items: center;
  display: grid;
  /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  grid-template-columns: repeat(1, minmax(0, 1fr));
}


.introduce-center {
  font-family: '微软雅黑', sans-serif;
  font-style: normal;
  color: #eee;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introduce-title {
  font-size: 4rem;
  font-weight: 700;
}

.introduce-describe-top {
  margin-top: 80px;
}

.introduce-describe {
  font-size: 2rem;
  color: #ddd;
  font-weight: 700;
}

button {
  margin-top: 2em;
  width: 150px;
  height: 40px;
  background-color: rgba(35, 35, 35, 1);
  border-radius: 32px;
  border: 1px solid rgb(59, 59, 59);
  font-family: 微软雅黑, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem;
  text-align: center;
  line-height: 2.3em;
  color: white;
}

button:hover {
  background-color: rgba(59, 59, 59, 1);
}

@media screen and (max-width:1440px) {}

@media screen and (max-width:730px) {}
</style>
