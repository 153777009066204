<script setup lang="ts">
interface Props {
  copywrite: string;
  bg: string;
  navigation: Navigation[];
  copyright: ICopyright;
  qrCode: string;
}

interface ICopyright {
  name: string;
  logo?: string;
  href?: string
}

interface Navigation {
  name: string;
  enName: string;
  item: NavigationItme[];
}
interface NavigationItme {
  name: string;
  href: string;
}
const props = defineProps<Props>();
const goto = (path: string) => {
  navigateTo(path)
}
</script>
<template>
  <div class="footer">
    <div class="navigation">
      <template v-for="item in navigation">
        <div class="navigation-item">
          <span class="navigation-item-title">{{ item.name }}</span>
          <span class="navigation-item-en">{{ item.enName }}</span>
          <template v-for="i in item.item">
            <p class="navigation-item-li" @click="goto(i.href)">{{ i.name }}</p>
          </template>
        </div>
      </template>
      <img class="qr-code" :src="qrCode" />
    </div>
    <div class="logo">
      <span class="logo-name"><a :href="copyright.href" target="_blank"> {{ copyright.name }}</a></span>
      <img v-if="copyright.logo" class="logo-img" :src="copyright.logo" />
    </div>
    <p class="footer-copywrite">{{ copywrite }}</p>
  </div>
</template>

<style scoped>
.footer {
  width: 100%;
  min-height: 280px;
  height: auto;
  background: v-bind(bg);
  font-family: '微软雅黑', sans-serif;
  font-style: normal;
  color: #cccccc;
  text-align: center;
  position: relative;
}

.navigation {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-items: center;
}

.navigation-item {
  width: 300px;
  text-align: left;
  padding-left: 30px;
  margin-bottom: 30px;
}

.navigation-item-title {
  width: 100%;
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  font-weight: 700;
  color: #c4c7c5;
}

.navigation-item-en {
  padding-left: 4px;
  font-size: 12px;
  color: #c4c7c5;
}

.navigation-item-li {
  height: 45px;
  line-height: 45px;
  font-size: 12px;
  font-weight: 400;
}

.qr-code {
  width: 150px;
  height: 150px;
  margin-top: 50px;
}

.logo {
  /* padding-top: 10px; */
}

.logo-name {
  font-size: 13px;
}

.logo-img {
  display: inline-block;
  width: 30px;
  height: 12px;
}

.footer-copywrite {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

.footer-copywrite,
.logo,
.navigation-item-li {
  cursor: pointer;
}

@media screen and (max-width:1260px) {
  .navigation {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width:745px) {
  .navigation {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .logo {
    padding-top: 50px;
  }
}
</style>
